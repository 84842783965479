import { createRouter, createWebHistory } from 'vue-router'

import Index from "@/components/Index.vue";

const routes = [
    {
        path: '/',
        components: {
            default: Index
        }
    },
    {
        path: '/sentiment',
        name: 'sentiment',
        components: {
            default: Index
        },
        props: {
            default: {'skipTo': 'sentiment'}
        }
    },
    {
        path: '/chat',
        name: 'chat',
        components: {
            default: Index
        },
        props: {
            default: {'skipTo': 'chat'}
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router