// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUwq74FAENKfpnmGicd1G5mutRMO_mPvw",
  authDomain: "emotion-detection-81edc.firebaseapp.com",
  projectId: "emotion-detection-81edc",
  storageBucket: "emotion-detection-81edc.appspot.com",
  messagingSenderId: "408935905107",
  appId: "1:408935905107:web:8d9d7cf0c362f74affd3a6",
  measurementId: "G-QYHDWEPC3D"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
export { auth, signInAnonymously };