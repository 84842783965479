import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as faceapi from '@vladmandic/face-api'
import Chat from 'vue3-beautiful-chat'

const app = createApp(App)
app.use(router)
app.use(Chat)
app.config.globalProperties.$tf = faceapi.tf
app.config.globalProperties.$faceapi = faceapi

app.mount('#app')
