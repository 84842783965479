<template>
  <div class="chat">
    <div class="header py-5">
      <MDBContainer class="pt-0 main-container" ref="chatContainer">
        <MDBRow class="py-md-3" center>
          <MDBCol md="5">
            <img src="@/assets/images/chat.png" class="chat-logo img-fluid" alt="Chat Image" />
          </MDBCol>
        </MDBRow>
        <MDBRow class="py-md-3" center>
          <MDBCol md="8">
            <h1 class="text-center">Welcome to the MentorsChat</h1>
          </MDBCol>
        </MDBRow>
        <MDBRow class="pb-5">
          <MDBCol>
            <MDBBtnGroup size="lg">
              <MDBBtn :color="roberta ? 'primary' : 'light'" class="text-center px-3" @click="roberta = true"><span class="text-unset">RoBERTa</span></MDBBtn>
              <MDBBtn :color="roberta ? 'light' : 'primary'" class="text-center px-4" @click="roberta = false">GPT-4</MDBBtn>
            </MDBBtnGroup>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <img v-if="!chatFocused" class="message-icon" width="30" src="@/assets/images/comment-dots-solid.svg" />
            <beautiful-chat
              :participants="participants"
              :titleImageUrl="titleImageUrl"
              :onMessageWasSent="onMessageWasSent"
              :messageList="currentMessageList"
              :newMessagesCount="newMessagesCount"
              :isOpen="true"
              :close="closeChat"
              :open="openChat"
              :showEmoji="false"
              :showFile="false"
              :showEdition="true"
              :showDeletion="true"
              :showHeader="false"
              :deletionConfirmation="true"
              :showTypingIndicator="showTypingIndicator"
              :showLauncher="false"
              :showCloseButton="false"
              :colors="colors"
              :alwaysScrollToBottom="alwaysScrollToBottom"
              :disableUserListToggle="true"
              :messageStyling="messageStyling"
              @onType="handleOnType"
              @edit="editMessage" />
            <MDBRow v-show="showIndicator">
              <MDBCol>
                <div class="typing-indicator">
                  <p class="d-inline-block">Model response is generating</p>
                  <MDBSpinner grow color="primary" size="sm" class="mx-1"  />
                  <MDBSpinner grow color="primary" size="sm" class="me-1"/>
                  <MDBSpinner grow color="primary" size="sm" />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  </div>
</template>

<script>
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBSpinner,
  MDBBtn,
  MDBBtnGroup
} from 'mdb-vue-ui-kit'
import { auth, signInAnonymously } from '@/firebase/init'
export default {
  name: "ChatBot",
  components: {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBSpinner,
    MDBBtn,
    MDBBtnGroup
  },
  async created(){
    await this.openChat()
    await this.sendTypedMessage(`Ask me about Mentors & More`)
    const inputElement = document.getElementsByClassName('sc-user-input--text')[0];

    signInAnonymously(auth)
      .then(() => {
        console.log('Signed in as anonymous');
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(`Error code: ${errorCode}, message: ${errorMessage}`);
      });

    inputElement.addEventListener("focus", this.handleFocus)
    inputElement.addEventListener("blur", this.handleBlur)
  },
  beforeUnmount() {
    const inputElement = document.getElementsByClassName('sc-user-input--text')[0];
    if (inputElement) {
      inputElement.removeEventListener("focus", this.handleFocus)
      inputElement.removeEventListener("blur", this.handleBlur)
    }
  },
  data(){
    return {
      lastMessage: '',
      currentMessageList: [],
      roberta: true,
      needs: [],
      behaviors: [],
      motives: [],
      beliefs: [],
      problemSummary: '',
      gptChatModel: false,
      sentFirstMessage: false,
      chatFocused: false,
      showIndicator: false,
      newRoom: {
        roomId: '',
        participants: [
          {
            id: 'user1',
            name: 'MentorsChat',
            imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
          },
        ], // the list of the messages to show, can be paginated and adjusted dynamically
        newMessagesCount: 0,
        isChatOpen: false, // to determine whether the chat window should be open or closed
        showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
        alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
        messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
      },
      clientRef: '',
      chatId: '',
      messageSnap: '',
      submitted: false,
      participants: [
        {
          id: 'user1',
          name: 'MentorsChat',
          imageUrl: ''
        },
        {
          id: 'chat-user',
          name: 'Anon',
          imageUrl: ''
        }
      ], // the list of all the participant of the conversation. `name` is the username, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: '',
      streamFinished: true,
      newMessagesCount: 0,
      isChatOpen: false, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#54b3a3',
          text: '#ffffff'
        },
        launcher: {
          bg: '#54b3a3'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#54b3a3',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    }
  },
  methods: {
    handleFocus(){
      this.chatFocused = true
    },
    handleBlur(){
      this.chatFocused = false
    },
    async onMessageWasSent (message) {
      const messageData = {
        'author': 'me',
        'user': 'Anon',
        'data': {
          'text': message.data.text
        },
        'type': 'text'
      };
      this.currentMessageList = [...this.currentMessageList, messageData]
      this.showIndicator = true
      this.scrollToBottom()
      console.log(message.data.text)
      let response
      if (this.roberta) {
        response = await fetch(
          `https://us-central1-chat-emts.cloudfunctions.net/roberta-chatbot`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({question: {text: message.data.text}})
          }
        )
        if (!response.ok) {
          console.log(`An error occurred: ${response.status}`)
          return
        }

        const data = await response.json()

        // Handle the received data
        console.log('data received from server: ', data)
        let botMessage = {
          'author': 'ChatModel',
          'user': 'Bot',
          'data': {
            'text': this.capitalizeFirstLetter(data['answer'])
          },
          'type': 'text'
        };
        this.currentMessageList = [...this.currentMessageList, botMessage]

        // Handle the received data
        // console.log('data received from server: ', data.body)
        this.showIndicator = false
      }
      else {
        await this.onGPTMessageWasSent(message)
      }
      this.scrollToBottom()
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async sendTypedMessage(message) {
      this.streamFinished = false
      let messageList = message.split(' ')
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      const vm = this;
      async function executeSlowly(list) {
        for (const word of list) {
          await delay(50);
          vm.addToLastMessage(word + ' ');
        }
      }

      await executeSlowly(messageList);
      this.streamFinished = true
    },
    async onGPTMessageWasSent (message) {
      var messageData = {
        'msisdn': '18335786996',
        'to': this.phone,
        'author': 'me',
        'user': this.fullName,
        'data': {
          'text': message.data.text
        },
        'type': 'text'
      }

      console.log('user scrolled set to false')
      this.userScrolled = false
      this.scrollToBottom()

      let eventSource = new EventSource(
          `https://us-central1-chat-emts.cloudfunctions.net/streaming-gpt?question=${encodeURIComponent(JSON.stringify({text: messageData.data.text }))}`
        )

      eventSource.addEventListener('message', onData);
      eventSource.addEventListener('error', onError)
      eventSource.addEventListener('open', onOpen);

      var vm = this
      function onOpen() {
        console.log('Connection to server opened');
      }

      function onData(event) {
        // Handle data received from the stream
        if (event.data === 'STREAM_COMPLETE') {
          console.log('Stream complete!')
          onComplete()
        } else {
          let newData = event.data
          const regex = /\b(?:e\.g\.|i\.e\.)|([.!?:](?!\s))/gm
          const charRegex = /[A-Za-z0-9]/
          if ((regex.test(vm.lastMessage.slice(-1)) && !event.data.startsWith(' '))
            || ((/[a-zA-Z]/.test(vm.lastMessage.slice(-1)) && event.data.match(/^\d/)))
            || ((charRegex.test(vm.lastMessage.slice(-1)) && event.data.startsWith('- ')))) {
            newData = '\n\n' + newData
          }
          vm.addToLastMessage(newData)
          vm.streamFinished = false
        }
      }
      let onComplete = () => {
        // Stream finished, remove event listeners
        vm.streamFinished = true
        eventSource.removeEventListener('message', onData);
        eventSource.removeEventListener('error', onError);
        eventSource.removeEventListener('open', onOpen)
        eventSource.close()
        vm.showIndicator = false
        if (!vm.userScrolled) {
          vm.scrollToBottom()
        }
      }

      function onError(error) {
        setTimeout(() => {
          if (eventSource.readyState === EventSource.CLOSED) {
            console.log('Connection was closed by the server');
            // Handle connection closed by server
            onComplete();
          } else {
            console.log(error)
            console.log('An error occurred while streaming the data from the server: ' + error.message)
          }
        }, 2000);      }

      console.log('new message sent: ' + messageData)
      this.showIndicator = true
    },
    addToLastMessage(text) {
      if (this.streamFinished) {
        this.lastMessage = ''
      }
      this.lastMessage = this.lastMessage + text;
      const botMessage = {
        'author': 'ChatModel',
        'user': 'Bot',
        'data': {
          'text': this.lastMessage
        },
        'type': 'text'
      };
      if (this.streamFinished) {
        this.currentMessageList = [...this.currentMessageList, botMessage]
      } else {
        this.currentMessageList = [...this.currentMessageList.slice(0, -1), botMessage]
      }
    },
    scrollToBottom(){
      if (this.$refs.chatContainer !== undefined) {
        // var container = this.$refs.chatContainer
        // container.$el.scrollTop = container.$el.scrollHeight
        // if (window.matchMedia('(min-device-width: 768px)').matches) {
        //   window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        // } else {
        //   var chatMessage = document.querySelectorAll(".sc-message:nth-last-of-type(2)")
        //   if (chatMessage[0]){
        //     window.scroll({
        //       top: chatMessage[0].offsetTop + chatMessage[0].offsetParent.offsetTop,
        //       left: 0,
        //       behavior: 'smooth'
        //     })
        //   }
          // Get last message in the chat
        // Get the chat container
        var chatContainer = document.querySelector(".sc-message-list");

        // If the chat container exists
        if (chatContainer) {
          // Calculate the bottom position of the container relative to the whole document
          var bottomPosition = chatContainer.getBoundingClientRect().bottom + window.pageYOffset;

          // Scroll the window to the bottom position
          window.scrollTo({ top: bottomPosition, behavior: "smooth" });
        }
      }
    },
    async openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      // setTimeout(document.getElementsByClassName('sc-user-input--text')[0].focus(), 1.5)

      this.newMessagesCount = 0
      console.log('open chat called')

      let newClientData = {
        id: 'user2',
        name: 'Anon',
        email: '',
        phone: '',
        zip: '',
        clientRef: '',
        imageUrl: ''
      }

      this.newRoom.participants.push(newClientData)
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.messageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    }
  },
  computed: {
    // displayedMessageList(){
    //   if (this.lastMessage.length !== 0) {
    //     const regex = /\s*(\[EN(?:D_SUMMARY)?])\s*/g;
    //     const lastMessage = this.lastMessage.join('').replace(regex, '')
    //     var messageData = {
    //       'author': 'ChatModel',
    //       'data': {
    //         'text': lastMessage
    //       },
    //       'type': 'text'
    //     }
    //     // this.currentMessageList = [
    //     //   ...(Array.isArray(this.currentMessageList) ? this.currentMessageList : []),
    //     //   messageData
    //     // ]
    //     return this.currentMessageList
    //   } else {
    //     return this.currentMessageList
    //   }
    // }
  },
  watch: {
    'displayedMessageList': {
      handler(newValue, ) {
        if (newValue && newValue.length > 1){
          if (newValue.slice(-1)[0].author === 'ChatModel'){
            this.showIndicator = false
            this.scrollToBottom()
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style>
.chat {
  background-color: #efefef;
  min-height: 100vh;
}
.bg-teal {
  background-color: #54b3a3;
}

.header {
  overflow: hidden;
  position: relative;
  /*background-size: cover;*/
  background-position: center center;
  min-height: calc(100vh - 160px);
}

.main-container {
  position: relative;
}

.probsolv-logo {

}

.main-headline {
  /*text-shadow: 2px 2px 2px rgba(90,90,90,0.6);*/
  font-weight: 900;
}

.pointer {
  cursor: pointer;
}

.chat-indicator {
  position: fixed;
  width: 75px;
  right: 5%;
  bottom: 5%;
  cursor: pointer;
}

.chat-modal {
  height: 370px;
}

.sc-message {
  width: 90% !important;
  margin: auto 5% !important;
}
.sc-message--content.sent {
  max-width: calc(100% - 20px);
}

.chat  .sc-message--content.sent .sc-message--text {
  max-width: calc(100% - 30px) !important;
  background-color: #68b5e1 !important;
  color: #180798 !important;
}

.sc-message--text .sc-message--text-content {
  margin-bottom: 0 !important;
  font-family: 'Prompt', sans-serif;
  font-size: 1.2rem;
}

.chat .sc-message--content.received .sc-message--text {
  background-color: #2f1b53 !important;
  color: #b3c1cb !important;
}

.chat .sc-user-input--text {
  width: 93%;
  font-size: 1.1rem !important;
  line-height: 1.3rem !important;
}

.chat .sc-user-input--text:empty:before {
  content: '\a0\a0\a0\a0\a0\a0\a0\a0 Let\'s go...';
}

.chat .sc-user-input--text:empty:focus:before {
  content: 'Let\'s go...';
}

.chat .sc-user-input--button {
  align-self: center;
  width: 50px !important;
}

.received .sc-message--text:after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: #efefef;
  border-bottom-right-radius: 10px;
}

.received .sc-message--text:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #2f1b53;
  border-bottom-right-radius: 15px;
}

.sent .sc-message--text:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: -2px;
  right: -8px;
  height: 20px;
  width: 20px;
  background: #68b5e1;
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.sent .sc-message--text:after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: #efefef;
  border-bottom-left-radius: 10px;
}

.chat .sc-user-input {
  text-align: left;
  position: fixed;
  width: 95%;
  left: 2.5%;
  bottom: 15px;
  background-color: #ffffff !important;
  border-radius: 25px !important;
  z-index: 1001 !important;
}

div:has(> .sc-user-input) {
  position: fixed;
  bottom:0;
  background-color: #efefef;
  height: 60px;
  width: 120%;
  margin-left: -10%;
}

.chat .behind-input {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: #efefef;
  z-index: 997;
}

.message-icon {
  position: fixed;
  left: 8%;
  bottom: 28px;
  z-index: 999;
}

@media screen and (min-width: 768px) {
  .chat .sc-user-input {
    width: 70%;
    left: 15%;
  }

  .message-icon {
    left: 16.5%;
  }
}

.chat .sc-chat-window {
  width: 100% !important;
  position: relative !important;
  background: transparent !important;
  max-height: unset !important;
  height: unset !important;
  bottom: 45px !important;
  box-shadow: none !important;
  font-family: 'Prompt', sans-serif !important;
}
.sc-chat-window.opened {
  z-index: 998;
}

.text-unset {
  text-transform: none !important;
}
.chat .sc-message-list {
  height: unset !important;
  background-color: transparent !important;
  padding-bottom: 0 !important;
}

.sc-message--text-content {
  text-align: left;
}

.typing-indicator {
  position: relative;
  margin-left: 15px;
  z-index: 999;
  text-align: left;
}

.extra-padding {
  padding-bottom: 80px;
}

.send-icon {
  pointer-events: none;
  position: fixed;
  right: 18%;
  bottom: 40px;
  z-index: 999;
  cursor: pointer;
}

.chat .sc-user-input--buttons {
  right: 10px !important;
}

.sc-user-input--button-icon-wrapper svg {
  width: 50px;
  height: 30px;
}

@media screen and (max-width: 768px){
  .button-width {
    width: 100%;
  }
}
</style>