<template>
  <div class="hello">
    <header>
      <!-- Navbar -->
      <MDBNavbar expand="lg" light bg="white" container class="pointer">
        <MDBNavbarToggler tag="button" target="#navbar" @click="navbar = !navbar"></MDBNavbarToggler>
        <MDBCollapse v-model="navbar" id="navbar">
          <MDBNavbarNav class="mb-2 mb-lg-0 text-primary">
            <MDBNavbarItem tag="li" :class="{'active': !sentimentAnalysis & !faceRecognition}" @click="sentimentAnalysis = false; faceRecognition = false">
              Home
            </MDBNavbarItem>
            <MDBNavbarItem tag="li" :class="{ 'active': faceRecognition }" @click="faceRecognition = true; sentimentAnalysis = false; chatBot = false">
              Facial Emotion Recognition
            </MDBNavbarItem>
            <MDBNavbarItem tag="li" :class="{ 'active': sentimentAnalysis }" @click="sentimentAnalysis = true; faceRecognition = false; chatBot = false">
              Sentiment Analysis
            </MDBNavbarItem>
            <MDBNavbarItem tag="li" :class="{ 'active': chatBot }" @click="chatBot = true; faceRecognition = false; sentimentAnalysis = false">
              Question Answering Chatbot
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
      <!-- Navbar -->
      <!-- Background image -->
      <div
          id="intro-example"
          class="p-5 text-center bg-image"
          :style="{'background-image': 'url(' + require('@/assets/images/detection.jpg') + ')'}"
          v-show="!faceRecognition & !sentimentAnalysis & !chatBot"
      >
        <div class="mask" style="background-color: rgba(0, 0, 0, 0.7);">
          <div class="d-flex justify-content-center align-items-center h-100">
            <div class="text-white">
              <h1 class="mb-3">Machine Learning Projects</h1>
              <h5 class="mb-4">Jeffrey Thomas - USD MS-AAI Program</h5>
              <MDBBtn lg tag="a" outline="light"
                      class="m-2"
                      role="button"
                      rel="nofollow"
                      @click="faceRecognition = true"
              >Facial Emotion Recognition</MDBBtn>
              <MDBBtn lg tag="a" outline="light"
                      class="m-2"
                      role="button"
                      @click="sentimentAnalysis = true"
              >Sentiment Analysis</MDBBtn>
              <MDBBtn lg tag="a" outline="light"
                      class="m-2"
                      role="button"
                      @click="chatBot = true"
              >Question Answering Chatbot</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <!-- Background image -->
    </header>
    <MDBContainer v-if="sentimentAnalysis">
      <MDBRow class="pt-5">
        <MDBCol>
          <div class="ratio ratio-1x1">
            <iframe
                src="https://jeffreykthomas-sentiment-nlp.hf.space"
                frameborder="0"
            ></iframe>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow center>
        <MDBCol md="6" class="my-auto">
          <a href="https://github.com/jeffreykthomas/sentiment-analysis" class="text-decoration-underline">
            <span>Code available on:
            </span>
            <img src="@/assets/images/GitHub-Emblem.png" class="w-50 d-inline-block" alt="Github">
          </a>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <MDBContainer v-if="faceRecognition">
      <MDBRow center v-show="!detectImage && !generateImage && !transformImage" class="pt-5">
        <MDBCol md="4">
          <h1>Choose task:</h1>
        </MDBCol>
      </MDBRow>
      <MDBRow center>
        <MDBCol lg="4" class="text-center" v-show="!detectImage && !generateImage  && !transformImage">
          <MDBBtn class="d-block mx-auto mt-3" rounded @click="detectionClick">Emotion Detection</MDBBtn>
          <MDBBtn class="d-block mx-auto my-3" rounded @click="generateClick">Generate A Face</MDBBtn>
          <MDBBtn class="d-block mx-auto my-3" rounded @click="transformClick">Transform A Face</MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow center class="pt-5" v-show="detectImage || generateImage || transformImage">
        <MDBTabs v-model="activeTabId" v-on:hide="hideTab" v-on:show="showTab">
          <!-- Tabs navs -->
          <MDBTabNav fill tabsClasses="mb-3">
            <MDBTabItem tabId="detection" href="detection">Emotion Detection</MDBTabItem>
            <MDBTabItem tabId="generate" href="generate">Generate A Face</MDBTabItem>
            <MDBTabItem tabId="transform" href="transform">Transform A Face</MDBTabItem>
          </MDBTabNav>
          <!-- Tabs navs -->
          <!-- Tabs content -->
          <MDBTabContent>
            <MDBTabPane tabId="detection"><Camera2 :detection=true v-if="detectImage"></Camera2></MDBTabPane>
            <MDBTabPane tabId="generate"><Camera2 :generate=true v-if="generateImage"></Camera2></MDBTabPane>
            <MDBTabPane tabId="transform"><Camera2 :transform=true v-if="transformImage"></Camera2></MDBTabPane>
          </MDBTabContent>
          <!-- Tabs content -->
        </MDBTabs>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <p>Disclaimer: No data is recorded or saved</p>
        </MDBCol>
      </MDBRow>
      <MDBRow center>
        <MDBCol md="6" class="my-auto">
          <a href="https://github.com/jeffreykthomas/emotion-recognition" class="text-black text-decoration-underline">
            <span>Code available on:
            </span>
            <img src="@/assets/images/GitHub-Emblem.png" class="w-50 d-inline-block" alt="Github">
          </a>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <MDBContainer v-if="chatBot" class="px-0 mx-0 min-vw-100">
      <ChatBot></ChatBot>
    </MDBContainer>
    <MDBFooter bg="light" :text="['center', 'white']">
      <!-- Grid container -->
      <MDBContainer class="p-4 pb-0">
        <!-- Section: Social media -->
        <section class="mb-4">
          <!-- Instagram -->
<!--          <MDBBtn-->
<!--              tag="a"-->
<!--              outline="dark"-->
<!--              href="#!"-->
<!--              floating-->
<!--              class="m-1"-->
<!--          >-->
<!--            <MDBIcon iconStyle="fab" icon="instagram" class="text-black"></MDBIcon>-->
<!--          </MDBBtn>-->
          <!-- Linkedin -->

            <MDBBtn
              tag="a"
              outline="dark"
              href="#!"
              floating
              class="m-1"
          >
              <a href="https://www.linkedin.com/in/jeffrey-thomas-431b7630/">
                <MDBIcon iconStyle="fab" icon="linkedin-in"></MDBIcon>
              </a>
          </MDBBtn>

          <!-- Github -->

            <MDBBtn
                tag="a"
                outline="dark"
                href="#!"
                floating
                class="m-1"
            >
              <a href="https://github.com/jeffreykthomas">
                <MDBIcon iconStyle="fab" icon="github"></MDBIcon>
              </a>
            </MDBBtn>

        </section>
        <!-- Section: Social media -->
      </MDBContainer>
      <!-- Grid container -->
      <!-- Copyright -->
      <div
          class="text-center p-3"
          style="background-color: rgba(0, 0, 0, 0)"
      >
        <a class="text-black" href="mailto:jeffreykthomas@gmail.com"
        >jeffreykthomas@gmail.com</a
        >
      </div>
      <!-- Copyright -->
    </MDBFooter>
  </div>
</template>

<script>
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTabs,
  MDBTabNav,
  MDBTabContent,
  MDBTabItem,
  MDBTabPane,
  MDBIcon,
  MDBFooter
} from 'mdb-vue-ui-kit'
import Camera2 from "@/components/Camera2";
import { ref } from 'vue';
import ChatBot from "@/components/ChatBot.vue";
export default {
  name: 'Index',
  components: {
    ChatBot,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    Camera2,
    MDBTabs,
    MDBTabNav,
    MDBTabContent,
    MDBTabItem,
    MDBTabPane,
    MDBIcon,
    MDBFooter
  },
  data(){
    return {
      detectImage: false,
      generateImage: false,
      transformImage: false,
      faceRecognition: false,
      sentimentAnalysis: false,
      chatBot: false,
    }
  },
  props: { 'skipTo': String },
  setup() {
    const activeTabId = ref('ex1-1');
    const navbar = ref(false)
    return {
      activeTabId,
      navbar
    };
  },
  methods: {
    detectionClick(){
      this.activeTabId = 'detection'
      this.useCamera = true
    },
    generateClick(){
      this.activeTabId = 'generate'
      this.generateImage = true
    },
    transformClick(){
      this.activeTabId = 'transform'
      this.transformImage = true
    },
    hideTab(event){
      switch (event.target.id) {
        case 'tab-detection':
          this.detectImage = false
          break
        case 'tab-generate':
          this.generateImage = false
          break
        case 'tab-transform':
          this.transformImage = false
      }
    },
    showTab(event){
      switch (event.target.id) {
        case 'tab-detection':
          this.detectImage = true
          break
        case 'tab-generate':
          this.generateImage = true
          break
        case 'tab-transform':
          this.transformImage = true
      }
    }
  },
  mounted(){
    if (this.skipTo === 'sentiment') {
      this.sentimentAnalysis = true
    } else if (this.skipTo === 'face') {
      this.faceRecognition = true
    } else if (this.skipTo === 'chat') {
      this.chatBot = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

@media (prefers-color-scheme: dark) {
  .dark-mode {
    background-color: rgb(11 15 25);
    color: white;
  }
}

#intro-example {
  height: 400px;
}
.ratio-1x1 {
  --mdb-aspect-ratio: 350%;
}

@media screen and (min-width: 768px) {
  .ratio-1x1 {
    --mdb-aspect-ratio: 140%;
  }
  #intro-example {
    height: 600px;
  }
}

@media screen and (min-width: 1068px) {
  .ratio-1x1 {
    --mdb-aspect-ratio: 70%;
  }
}

.pointer {
  cursor: pointer;
}

.nav-item.active {
  font-weight: 900;
}

</style>
